import classNames from "classnames";
import { FC, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  close?: () => void;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

const SelectItem: FC<Props> = ({
  close,
  onClick,
  className,
  disabled,
  children,
}) => {
  return (
    <li
      onClick={() => {
        onClick && onClick();
        close && close();
      }}
      className={classNames(
        className,
        "text-text-primary transition-colors border-b border-zinc-600 last:border-0",
        { "cursor-default": disabled, " cursor-pointer": !disabled }
      )}
    >
      {children}
    </li>
  );
};

export default SelectItem;
