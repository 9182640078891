import { createSlice } from "@reduxjs/toolkit";
import { IState } from "./types";

const initialState: IState = {
  data: [],
  loading: true,
};

const notifSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    pending: (state) => {
      state.loading = true;
    },
    non_pending: (state) => {
      state.loading = false;
    },
    get_events: (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
    },
  },
});

export const { get_events, non_pending, pending } = notifSlice.actions;
export default notifSlice.reducer;
