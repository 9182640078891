import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Wrapper from "../common/wrapper";

const Signin = lazy(() => import("./../pages/signin"));

const AuthLayout = () => {
  return (
    <Wrapper>
      <Routes>
        <Route path="/signin" element={<Signin />} />
      </Routes>
    </Wrapper>
  );
};

export default AuthLayout;
