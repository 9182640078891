const UserIcon = ({ width, height }: { width?: string; height?: string }) => {
  return (
    <svg
      id="Iconly_Broken_Profile"
      data-name="Iconly/Broken/Profile"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "16"}
      height={height || "20"}
      viewBox="0 0 16 20"
    >
      <g id="Profile">
        <path
          id="Profile-2"
          data-name="Profile"
          d="M6.978,19.985a.717.717,0,0,1-.7-.738.737.737,0,0,1,.742-.7q.483.014.981.014c4.349,0,6.555-.751,6.555-2.233,0-1.5-2.206-2.255-6.555-2.255s-6.555.752-6.555,2.235c0,.485.173,1.188,1.675,1.694a.718.718,0,1,1-.464,1.359C.461,18.624,0,17.3,0,16.31c0-3.29,4.566-3.67,8-3.67,1.978,0,8,0,8,3.691C16,19.619,11.435,20,8,20Q7.48,20,6.978,19.985ZM2.613,5.354A5.387,5.387,0,1,1,8,10.706,5.377,5.377,0,0,1,2.613,5.354Zm1.446,0A3.942,3.942,0,1,0,8,1.437,3.933,3.933,0,0,0,4.059,5.354Z"
          fill="#c4c4c4"
        />
      </g>
    </svg>
  );
};

export default UserIcon;
