import { FC, PropsWithChildren } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

const Container: FC<PropsWithChildren> = ({ children }) => {
  const { language } = useSelector((state: RootState) => state.config);
  const { width } = useWindowDimensions();

  return (
    <div
      className={classNames("inline-block my-3", { "float-left": width > 1024 && language !== "en", "float-right": width > 1024 && language === "en", "px-4": width <= 1024 })}
      style={{ width: width >= 1024 ? "calc(85% - 15px)" : "calc(100%)" }}
    >
      {children}
    </div>
  );
};

export default Container;
