const NotificationIcon = () => {
  return (
    <svg
      id="Iconly_Broken_Notification"
      data-name="Iconly/Broken/Notification"
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="20"
      viewBox="0 0 17 20"
    >
      <g id="Notification" transform="translate(0)">
        <path
          id="Notification-2"
          data-name="Notification"
          d="M5.679,18.618a.756.756,0,0,1,.1-1.069.769.769,0,0,1,1.077.1,2.064,2.064,0,0,0,.418.413,1.987,1.987,0,0,0,2.777-.413h.009a.77.77,0,0,1,1.078-.1.755.755,0,0,1,.1,1.069,3.491,3.491,0,0,1-5.563,0Zm-1.641-2.78A4.834,4.834,0,0,1,.968,14.3,4.079,4.079,0,0,1,0,11.561v-.122a4.207,4.207,0,0,1,.728-2.1L.8,9.248a3.833,3.833,0,0,0,.887-1.76V5.675a.765.765,0,0,1,1.464.158V7.646a.431.431,0,0,1,0,.1,5.26,5.26,0,0,1-1.225,2.446,2.531,2.531,0,0,0-.39,1.25v.194a2.574,2.574,0,0,0,.585,1.715,3.319,3.319,0,0,0,2.076.986,40.05,40.05,0,0,0,8.588,0,3.326,3.326,0,0,0,2.12-1.03,2.543,2.543,0,0,0,.559-1.663v-.2a2.629,2.629,0,0,0-.381-1.258,5.269,5.269,0,0,1-1.3-2.446.584.584,0,0,1,0-.1V5.816c-.257-2.657-2.928-4.3-5.252-4.3a5.76,5.76,0,0,0-2.821.73.769.769,0,0,1-.777.005A.753.753,0,0,1,4.97.924,7.241,7.241,0,0,1,8.465,0c3.069,0,6.512,2.164,6.9,5.64V7.453a3.75,3.75,0,0,0,.887,1.76.747.747,0,0,1,.071.1A4.055,4.055,0,0,1,17,11.36l-.027.184a4.14,4.14,0,0,1-.941,2.754,4.884,4.884,0,0,1-3.123,1.54,40.7,40.7,0,0,1-4.435.242A40.723,40.723,0,0,1,4.038,15.838Z"
          fill="#3a3a3a"
        />
      </g>
    </svg>
  );
};

export default NotificationIcon;
