const SearchIcon = ({ width = "20", height = "20" }) => {
  return (
    <svg
      id="Iconly_Light-outline_Search"
      data-name="Iconly/Light-outline/Search"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
    >
      <g id="Search">
        <path
          id="Search-2"
          data-name="Search"
          d="M18.82,19.859l-.083-.07-3.128-3.05a9.7,9.7,0,0,1-6,2.06A9.518,9.518,0,0,1,0,9.4,9.518,9.518,0,0,1,9.611,0a9.518,9.518,0,0,1,9.612,9.4,9.25,9.25,0,0,1-2.531,6.349l3.091,3.014a.713.713,0,0,1,0,1.024.753.753,0,0,1-.964.071ZM1.48,9.4a8.052,8.052,0,0,0,8.131,7.952A8.052,8.052,0,0,0,17.743,9.4,8.052,8.052,0,0,0,9.611,1.448,8.052,8.052,0,0,0,1.48,9.4Z"
          fill="#c4c4c4"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
