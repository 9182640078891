import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "./types";

const initialState: IUser = {
  token: null,
  isLoggedIn: false,
  info: null,
  pending: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    pending: (state) => {
      state.pending = true;
    },
    non_pending: (state) => {
      state.pending = false;
    },
    success_login: (state, action) => {
      state.pending = false;
      state.isLoggedIn = true;
      state.token = action.payload.token;
      state.info = action.payload.info
    },
    logout: (state) => {
      state.token = null;
      state.isLoggedIn = false;
      state.info = null;
    },
    Unauthorized: (state) => {
      state.token = null;
      state.isLoggedIn = false;
      state.info = null;
    },
  },
});
export const { success_login, non_pending, pending, logout, Unauthorized } =
  userSlice.actions;
export default userSlice.reducer;
