import { PropsWithChildren, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";

interface IProps extends PropsWithChildren {
  parent?: Element;
  className?: string;
  style?: any; //CSS.Properties
}

const Portal = ({ parent, className, style, children }: IProps) => {
  const el:any = useMemo(() => document.createElement("div"), []);
  
  useEffect(() => {
    const target =
      parent && parent.hasOwnProperty("appendChild") ? parent : document.body;
    let classList = ["portal-container"];
    className?.split(" ").forEach((c) => classList.push(c));
    classList?.forEach((c) => el.classList.add(c));
    for (const p in style) {
      el.style[p] = style[p];
    }
    target.appendChild(el);
    return () => {
      target.removeChild(el);
    };
  }, [className, el, parent, style]);

  return createPortal(children, el);
};
export default Portal;
