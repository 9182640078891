const ConnectionGraphIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 19 20"
    >
      <g id="Group_137" data-name="Group 137" transform="translate(2 4.5)">
        <g
          id="Ellipse_62"
          data-name="Ellipse 62"
          transform="translate(-2 8.5)"
          fill="none"
          stroke="#c4c4c4"
          strokeWidth="1.5"
        >
          <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
          <circle cx="3.5" cy="3.5" r="2.75" fill="none" />
        </g>
        <g
          id="Ellipse_63"
          data-name="Ellipse 63"
          transform="translate(10 8.5)"
          fill="none"
          stroke="#c4c4c4"
          strokeWidth="1.5"
        >
          <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
          <circle cx="3.5" cy="3.5" r="2.75" fill="none" />
        </g>
        <g
          id="Ellipse_64"
          data-name="Ellipse 64"
          transform="translate(-2 -4.5)"
          fill="none"
          stroke="#c4c4c4"
          strokeWidth="1.5"
        >
          <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
          <circle cx="3.5" cy="3.5" r="2.75" fill="none" />
        </g>
        <line
          id="Line_11"
          data-name="Line 11"
          y2="2"
          transform="translate(1.5 4.5)"
          fill="none"
          stroke="#c4c4c4"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_13"
          data-name="Line 13"
          x1="2"
          transform="translate(6.5 12)"
          fill="none"
          stroke="#c4c4c4"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_68"
          data-name="Line 68"
          x1="2"
          transform="translate(6.5 -1)"
          fill="none"
          stroke="#c4c4c4"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default ConnectionGraphIcon;
