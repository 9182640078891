export const getLocalStorage = (key: string) => {
  return JSON.parse(`${localStorage.getItem(key)}`);
};
export const setLocalStorage = <T,>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};
export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
export const getSessionStorage = (key: string) => {
  return JSON.parse(`${sessionStorage.getItem(key)}`);
};
export const setSessionStorage = <T,>(key: string, value: T) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};
export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};