import MenuIcon from "../../assets/icons/MenuIcon";
import NotificationIcon from "../../assets/icons/NotificationIcon";
import { ISidebarData, sidebarData } from "../../data/sidebar";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { FC, useEffect, useState } from "react";
import Portal from "../../utils/Portal";
import classNames from "classnames";
import useClickOutSide from "../../hooks/useClickOutSide";
import { freezeScreen } from "../../utils/freezScreen";
import { api } from "../../services/api";
import { ToJalali } from "../../utils/DateFormater";
import SpinLoading from "../../components/loading/SpinLoading";
import { useDispatch, useSelector } from "react-redux";
import {
  get_events,
  non_pending,
} from "../../redux/features/notification/notifSlice";
import { useAxios } from "../../services/http";
import { handleCatchError } from "../../utils/parsers";
import { RootState } from "../../redux";
import { Link } from "react-router-dom";
import Language from "../../i18n";

interface IProps {
  closeSidebar: () => void;
  isOpenSidebar: boolean;
}

const SubItem = ({ item }: { item: ISidebarData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { language: lang } = useSelector((state: RootState) => state.config);
  return (
    <div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center my-5 relative [&_.CHILD-TITLE]:text-text-secondary cursor-pointer"
      >
        <div className="mr-5 ml-8">{item.icon}</div>
        <div className="CHILD-TITLE text-lg">{item.title}</div>
      </div>
      <div
        className={classNames("w-[calc(100%_-_50px)] mx-auto", {
          "border-r-2 border-r-blue-500": lang !== "en",
          "border-l-2 border-l-blue-500": lang === "en",
        })}
      >
        {item.children &&
          item.children.map(
            (j) =>
              isOpen &&
              (j.path.toString().includes("http") ? (
                <a
                  key={j.id}
                  href={j.path}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center my-5 relative [&_.CHILD-TITLE]:text-text-secondary"
                >
                  <div className="CHILD-TITLE text-md px-4">{j.title}</div>
                </a>
              ) : (
                <NavLink
                  key={j.id}
                  to={j.path}
                  className={({ isActive }) =>
                    isActive
                      ? `flex items-center my-5 relative [&_.CHILD-TITLE]:text-blue-500 [&_.CHILD-TITLE]:font-bold`
                      : `flex items-center my-5 relative [&_.CHILD-TITLE]:text-text-secondary`
                  }
                >
                  <div className="CHILD-TITLE text-md px-4">{j.title}</div>
                </NavLink>
              ))
          )}
      </div>
    </div>
  );
};

const Sidebar: FC<IProps> = ({ closeSidebar, isOpenSidebar }) => {
  const { data, loading } = useSelector((state: RootState) => state.events);
  const { language } = useSelector((state: RootState) => state.config);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const refSidebar = useClickOutSide(() => {
    !isOpenSidebar && closeSidebar();
  });
  const http = useAxios();

  useEffect(() => {
    freezeScreen(isOpenSidebar);
  }, [isOpenSidebar]);

  useEffect(() => {
    http(api.home.events.path)
      .then((res) => {
        if (res.data.status) {
          dispatch(get_events({ data: res.data.data }));
        } else {
          throw res.data.message;
        }
      })
      .catch((err) => {
        console.log(err);
        handleCatchError(err);
      })
      .finally(() => {
        dispatch(non_pending());
      });
  }, []);

  return width >= 1024 ? (
    <div
      className={classNames("inline-block my-1", {
        "float-left": language === "en",
        "float-right": language !== "en",
      })}
      style={{ width: "15%" }}
    >
      {/* menu */}
      <div className="menu bg-bg-primary overflow-y-auto rounded-lg my-2 py-3 px-2">
        <div className="flex items-center">
          <span className="text-text-secondary font-bold">
            <Language _t="Menu" />
          </span>
          <div className="flex-grow h-0.5 bg-bg-secondary mx-4" />
          <MenuIcon />
        </div>
        <div>
          {sidebarData.map((i) =>
            i.path !== null ? (
              <NavLink
                key={i.id}
                to={i.path}
                className={({ isActive }) =>
                  isActive
                    ? `flex items-center my-5 relative [&_.CHILD-TITLE]:text-blue-500 [&_.CHILD-TITLE]:font-bold before:absolute before:${
                        language !== "en" ? "right-0" : "left-0"
                      } before:h-full before:w-0.5 before:bg-blue-500`
                    : `flex items-center my-5 relative [&_.CHILD-TITLE]:text-text-secondary`
                }
              >
                <div className="mr-5 ml-8">{i.icon}</div>
                <div className="CHILD-TITLE text-lg">{i.title}</div>
              </NavLink>
            ) : (
              <SubItem key={i.id} item={i} />
            )
          )}
        </div>
      </div>
      {/* menu */}
      {/* notification */}
      <div className="notification bg-bg-primary rounded-lg my-2 py-3 px-2">
        <div className="flex items-center">
          <span className="text-text-secondary font-bold">
            <Language _t="Notifications" />
          </span>
          <div className="flex-grow h-0.5 bg-bg-secondary mx-4" />
          <NotificationIcon />
        </div>
        <div style={{ maxHeight: "330px" }} className="overflow-y-auto">
          {loading && <SpinLoading />}
          {!loading &&
            data.length > 0 &&
            data.map((i) => (
              <Link
                to={"/result-intersection"}
                state={i.id}
                key={i.id}
                className="block bg-bg-secondary my-2 p-2 rounded-l-lg border-r-4 hover:border-yellow-500 border-slate-300 relative"
              >
                <div className="text-md text-text-secondary">
                  <Language _t="crossing_of_group" /> {i.name}{" "}
                  <Language _t="was_done" />
                </div>
                <div className="text-left text-text-secondary text-xs">
                  {i.created_at ? ToJalali(i.created_at) : ""}
                </div>
              </Link>
            ))}
        </div>
      </div>
      {/* notification */}
    </div>
  ) : (
    <Portal>
      <div
        className={classNames(
          "back-layer w-full min-h-screen z-40 backdrop-blur-sm absolute top-0 left-0",
          { hidden: !isOpenSidebar }
        )}
      />
      <div
        ref={refSidebar}
        className={classNames(
          "fixed top-0 w-9/12 h-screen bg-bg-main transition-all z-50 py-6 overflow-y-auto",
          { "right-0": isOpenSidebar, "-right-full": !isOpenSidebar }
        )}
      >
        <div>
          <div>
            <div className="flex items-center px-4">
              <span className="text-text-primary ml-2">
                <Language _t="Menu" />
              </span>
              <span className="w-full h-0.5 bg-bg-secondary"></span>
            </div>

            {sidebarData.map((i) =>
              i.path !== null ? (
                <NavLink
                  onClick={() => closeSidebar()}
                  key={i.id}
                  to={i.path}
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center my-5 relative [&_.CHILD-TITLE]:text-blue-500 [&_.CHILD-TITLE]:font-bold before:absolute before:right-0 before:h-full before:w-0.5 before:bg-blue-500"
                      : "flex items-center my-5 relative [&_.CHILD-TITLE]:text-text-secondary"
                  }
                >
                  <div className="mr-5 ml-8">{i.icon}</div>
                  <div className="CHILD-TITLE text-lg">{i.title}</div>
                </NavLink>
              ) : null
            )}
          </div>

          <div>
            <div className="flex items-center px-4">
              <span className="text-text-primary ml-2">
                <Language _t="Notifications" />
              </span>
              <span className="w-full h-0.5 bg-bg-secondary"></span>
            </div>

            <div className="px-4">
              {data.length &&
                data.map((i) => (
                  <div
                    key={i.id}
                    className="bg-bg-secondary my-2 p-2 rounded-l-lg border-r-4 hover:border-yellow-500 border-slate-300 relative"
                  >
                    <div className="text-md text-text-secondary">
                      <Language _t="crossing_of_group" /> {i.name}{" "}
                      <Language _t="was_done" />
                    </div>
                    <div className="text-left text-text-secondary text-xs">
                      {ToJalali(i.created_at)}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Sidebar;
