import { useState, useCallback } from "react";
const useToggle = (initialValue:boolean = false) => {
  const [state, setState] = useState(initialValue);
  const isFalse = useCallback(() => setState(false), []);
  const isTrue = useCallback(() => setState(true), []);
  const toggle = useCallback(() => setState(state => !state), []);

  return { isFalse, isTrue, toggle, state };
};
export default useToggle;
