export const freezeScreen = (isfreeze:boolean) => {
    if(isfreeze){
        document.body.style.height = '100vh'
        document.body.style.overflow = 'hidden'
        // document.body.style.height = '100vh'
    }else{
        document.body.style.height = 'auto'
        document.body.style.overflow = 'unset'
        // document.body.style.height = '100vh'
    }
}