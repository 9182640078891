import { createSlice } from "@reduxjs/toolkit";
import { setLocalStorage } from "../../../utils/storages";

interface IConfig {
  language: "fa" | "ar" | "en";
}

const initialState: IConfig = {
  language: "fa",
};

interface IAction {
  payload: "fa" | "ar" | "en";
}

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    changeLanguage: (state, action: IAction) => {
      state.language = action.payload;
      setLocalStorage("language", action.payload);
      if (action.payload === "en") {
        document.body.dir = "ltr";
      } else {
        document.body.dir = "rtl";
      }
    },
  },
});

export const { changeLanguage } = configSlice.actions;

export default configSlice.reducer;
