import { Navigate } from "react-router";
import { getLocalStorage } from "./storages";
const ProtectedRoute = ({ children }: { children: any }) => {
  const token_local = getLocalStorage("token");

  if (!token_local) {
    return <Navigate to={"/auth/signin"} />;
  }
  return children;
};

export default ProtectedRoute;
