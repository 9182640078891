const FacebookIcon = ({width,height}:{width?: string, height?: string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "11.266"}
      height={height || "22.53"}
      viewBox="0 0 11.266 22.53"
    >
      <path
        id="facebook"
        d="M15.4,3.741h2.057V.159a26.559,26.559,0,0,0-3-.159c-2.966,0-5,1.865-5,5.294V8.449H6.187v4H9.46V22.53h4.012V12.455h3.14l.5-4h-3.64V5.691C13.472,4.533,13.783,3.741,15.4,3.741Z"
        transform="translate(-6.187)"
        fill="#fff"
      />
    </svg>
  );
};

export default FacebookIcon;
