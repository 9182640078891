import { createContext, FC, PropsWithChildren, useContext, useEffect } from "react";
import axios, { AxiosInstance } from "axios";
import baseUrl from "./config.json";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux";
import { success_login, Unauthorized } from "../redux/features/user/userSlice";
import { getLocalStorage, getSessionStorage, removeLocalStorage } from "../utils/storages";
import { useNavigate } from "react-router";

export type IAxiosInstance = AxiosInstance;
export type IContextValue = undefined | IAxiosInstance;

const HttpContext = createContext<IContextValue>(
  new Proxy(axios.create({ baseURL: baseUrl.server }), {
    apply: () => {
      throw new Error("You must wrap your component in an AxiosProvider");
    },
    get: () => {
      throw new Error("You must wrap your component in an AxiosProvider");
    },
  })
);
// IAxiosInstance
export const useAxios = () => useContext(HttpContext) as IAxiosInstance;

const AxiosProvider: FC<PropsWithChildren> = ({ children }) => {
  const { token } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate()
  const http = axios.create({ baseURL: baseUrl.server });

  const dispatch = useDispatch();

  let data_localstorage = getLocalStorage("token");
  let data_sessionStorage = getSessionStorage("token");
  useEffect(() => {
    if (JSON.parse(data_localstorage)) {
      dispatch(success_login({ token: JSON.parse(data_localstorage).__t, info: JSON.parse(data_localstorage).__i }));
    }
    //  else if (JSON.parse(data_sessionStorage)) {
    //   dispatch(success_login({ token: JSON.parse(data_sessionStorage).__t, info: JSON.parse(data_sessionStorage).__i }));
    // }
  }, [data_localstorage, dispatch]);
  http.interceptors.request.use(
    (onFulfilled) => {
      if (data_localstorage) {
        onFulfilled.headers.Authorization = `Bearer ${JSON.parse(data_localstorage).__t}`;
      }
      if (getLocalStorage("language")) {
        onFulfilled.headers.lang = getLocalStorage("language");
      } else {
        onFulfilled.headers.lang = "fa";
      }
      return onFulfilled;
    },
    (onRejected) => {
      console.log("request => ", onRejected);
      return Promise.reject(onRejected);
    }
  );
  http.interceptors.response.use(
    (onFulfilled) => onFulfilled,
    (onRejected) => {
      if (onRejected.response.status === 401 || onRejected.response.status === 429) {
        dispatch(Unauthorized());
        removeLocalStorage("token");
        navigate('/signin')
      }
      return Promise.reject(onRejected);
    }
  );

  if (http === undefined) {
    throw new Error("The component using the the context must be a descendant of the context provider");
  }

  return <HttpContext.Provider value={http}>{children}</HttpContext.Provider>;
};
export default AxiosProvider;
