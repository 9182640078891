const CommonIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="13"
      viewBox="0 0 18 13"
    >
      <g id="Group_64" data-name="Group 64" transform="translate(-1855 -651)">
        <path
          id="Intersection_1"
          data-name="Intersection 1"
          d="M1646,193.5a4.334,4.334,0,1,1,4,6A6.5,6.5,0,0,1,1646,193.5Z"
          transform="translate(214 464)"
          fill="#2a2a2a"
        />
        <g
          id="Ellipse_6"
          data-name="Ellipse 6"
          transform="translate(1855 651)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
        >
          <circle cx="6.5" cy="6.5" r="6.5" stroke="none" />
          <circle cx="6.5" cy="6.5" r="6" fill="none" />
        </g>
        <g
          id="Ellipse_7"
          data-name="Ellipse 7"
          transform="translate(1860 651)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
        >
          <circle cx="6.5" cy="6.5" r="6.5" stroke="none" />
          <circle cx="6.5" cy="6.5" r="6" fill="none" />
        </g>
      </g>
    </svg>
  );
};

export default CommonIcon;
