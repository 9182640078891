const DesktopIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g id="Group_66" data-name="Group 66" transform="translate(-1857 -370)">
        <g
          id="Rectangle_36"
          data-name="Rectangle 36"
          transform="translate(1857 370)"
          fill="none"
          stroke="#7a7a7a"
          strokeWidth="1"
        >
          <rect width="8" height="8" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="7" height="7" rx="1.5" fill="none" />
        </g>
        <g
          id="Rectangle_37"
          data-name="Rectangle 37"
          transform="translate(1867 370)"
          fill="none"
          stroke="#7a7a7a"
          strokeWidth="1"
        >
          <rect width="8" height="8" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="7" height="7" rx="1.5" fill="none" />
        </g>
        <g
          id="Rectangle_38"
          data-name="Rectangle 38"
          transform="translate(1867 380)"
          fill="none"
          stroke="#7a7a7a"
          strokeWidth="1"
        >
          <rect width="8" height="8" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="7" height="7" rx="1.5" fill="none" />
        </g>
        <g
          id="Rectangle_39"
          data-name="Rectangle 39"
          transform="translate(1857 380)"
          fill="none"
          stroke="#7a7a7a"
          strokeWidth="1"
        >
          <rect width="8" height="8" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="7" height="7" rx="1.5" fill="none" />
        </g>
      </g>
    </svg>
  );
};

export default DesktopIcon;
