import UserIcon from "../../assets/icons/UserIcon";
import useToggle from "../../hooks/useToggle";
import SettingIcon from "../../assets/icons/SettingIcon";
import LogoutIcon from "../../assets/icons/LogoutIcon";
import { useAxios } from "../../services/http";
import { api } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/features/user/userSlice";
import { toast } from "react-toastify";
import { removeLocalStorage } from "../../utils/storages";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../redux";
import { Fragment, useCallback, useState } from "react";
import Modal from "../../components/modal";
import StandardField from "../../components/textFields/StandardField";
import { Field, Form } from "react-final-form";
import Button from "../../components/button/Button";
import SpinLoading from "../../components/loading/SpinLoading";
import FormSelect from "../../components/select-menu/FormSelect";
import LabelSelect from "../../components/select-menu/LabelSelect";
import Select from "../../components/select-menu/Select";
import SelectItem from "../../components/select-menu/SelectItem";
import EarthIcon from "../../assets/icons/Earth";
import { changeLanguage } from "../../redux/features/config/configSlice";
import Language from "../../i18n";
import useTranslation from "../../hooks/useTranslation";
import ListIcon from "../../assets/icons/ListIcon";

const { path: path_logout } = api.auth.logout;

const Header = ({ openSidebar }: { openSidebar: () => void }) => {
  const [loading, setLoading] = useState(false);
  const { info } = useSelector((state: RootState) => state.user);
  const { isFalse: closeChangePasswordModal, isTrue: openChangePasswordModal, state: isOpenChangePasswordModal } = useToggle();
  const http = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const t = useTranslation();

  const handleChangePassword = async (values: any) => {
    setLoading(true);
    await http(api.userManagement.change_password.path, { method: api.userManagement.change_password.method, data: { password: values.password } })
      .then((res) => {
        if (res.data.status) {
          toast(res.data.message, { type: "success" });
          closeChangePasswordModal();
        }
      })
      .catch((err) => {
        handleChangePassword(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleLogout = async () => {
    await http(path_logout)
      .then((res) => {
        if (res.data.status) {
          dispatch(logout());
          removeLocalStorage("token");
          navigate("/signin");
        }
      })
      .catch((error) => {
        const msg = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        toast(msg);
      });
  };
  const changePanel = useCallback(() => {
    if (location.pathname.includes("rased")) {
      navigate("/iwf");
    } else {
      navigate("/rased/main");
    }
  }, [location.pathname, navigate]);

  return (
    <Fragment>
      <header className="p-3 bg-bg-primary w-full flex justify-between items-center rounded-b-lg">
        <div className="hidden lg:block">
          <strong className="text-text-primary tracking-wider">{info?.email}</strong>
        </div>
        <div>
          <button className="flex flex-col lg:hidden" onClick={openSidebar}>
            <div className="w-6 h-0.5 bg-white my-0.5"></div>
            <div className="w-6 h-0.5 bg-white my-0.5"></div>
            <div className="w-6 h-0.5 bg-white my-0.5"></div>
          </button>
        </div>
        <div className="flex gap-2">
          <FormSelect>
            <LabelSelect hasArrow={false} className="py-2.5 px-3 bg-bg-secondary">
              <UserIcon />
            </LabelSelect>
            <Select>
              <SelectItem className="bg-bg-secondary p-2 text-center hover:bg-bg-primary" onClick={openChangePasswordModal}>
                <SettingIcon />
              </SelectItem>
              <SelectItem className="bg-bg-secondary p-2 text-center hover:bg-bg-primary" onClick={changePanel}>
                <ListIcon />
              </SelectItem>
              <SelectItem className="bg-bg-secondary p-2 text-center hover:bg-bg-primary" onClick={handleLogout}>
                <LogoutIcon />
              </SelectItem>
            </Select>
          </FormSelect>
          <FormSelect>
            <LabelSelect hasArrow={false} className="p-3 bg-bg-secondary">
              <EarthIcon />
            </LabelSelect>
            <Select>
              <SelectItem className="bg-bg-secondary px-2 text-center hover:bg-bg-primary py-1" onClick={() => dispatch(changeLanguage("fa"))}>
                fa
              </SelectItem>
              <SelectItem className="bg-bg-secondary px-2 text-center hover:bg-bg-primary py-1" onClick={() => dispatch(changeLanguage("ar"))}>
                ar
              </SelectItem>
              <SelectItem className="bg-bg-secondary px-2 text-center hover:bg-bg-primary py-1" onClick={() => dispatch(changeLanguage("en"))}>
                en
              </SelectItem>
            </Select>
          </FormSelect>
        </div>
      </header>
      <Modal close={closeChangePasswordModal} isOpen={isOpenChangePasswordModal} className="bg-bg-secondary px-8 py-8">
        <h3 className="text-center text-2xl font-bold text-text-main">
          <Language _t="change_password" />
        </h3>
        <Form
          onSubmit={handleChangePassword}
          validate={(values) => {
            const errors: any = {};
            if (!values.password) {
              errors.password = t("Enter_your_password");
            }
            if (values.password && values.password.length < 8) {
              errors.password = t("password_must_be_at_least_8_characters_long");
            }
            if (!values.confirm_password) {
              errors.confirm_password = t("repeat_change_password");
            }
            if (values.password !== values.confirm_password) {
              errors.confirm_password = t("entered_password_is_not_equal_to_repeating_it");
            }
            return errors;
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="password"
                render={({ input, meta }) => (
                  <StandardField
                    boxInputClassName="my-6"
                    inputClassName="bg-bg-primary"
                    type="password"
                    label={<Language _t="new_password" />}
                    failed={meta.error && meta.touched}
                    textError={meta.error}
                    {...input}
                  />
                )}
              />
              <Field
                name="confirm_password"
                render={({ input, meta }) => (
                  <StandardField
                    boxInputClassName="my-6"
                    inputClassName="bg-bg-primary"
                    type="password"
                    label={<Language _t="repeat_new_password" />}
                    failed={meta.error && meta.touched}
                    textError={meta.error}
                    {...input}
                  />
                )}
              />
              <Button className="w-full" type="submit" disabled={loading}>
                {loading ? <SpinLoading size="sm" /> : <Language _t="Record" />}
              </Button>
            </form>
          )}
        />
      </Modal>
    </Fragment>
  );
};

export default Header;
