const MenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.005"
      height="15.468"
      viewBox="0 0 17.005 15.468"
    >
      <g
        id="Group_128"
        data-name="Group 128"
        transform="translate(-1619.668 -146.946)"
      >
        <path
          id="Path_34123"
          d="M1.408,2.259H15.442a1.281,1.281,0,0,0,1.391-1.129A1.281,1.281,0,0,0,15.442,0H1.391A1.281,1.281,0,0,0,0,1.129,1.281,1.281,0,0,0,1.391,2.259Z"
          transform="translate(1619.668 160.155)"
          fill="#3a3a3a"
        />
        <path
          id="Path_34124"
          d="M1.271,2.28H15.3a1.538,1.538,0,0,0,1.348-.529.965.965,0,0,0,0-1.215A1.538,1.538,0,0,0,15.3.007H1.254A1.262,1.262,0,0,0,0,1.143,1.262,1.262,0,0,0,1.254,2.28Z"
          transform="translate(1619.805 153.537)"
          fill="#3a3a3a"
        />
        <path
          id="Path_34125"
          d="M1.254,2.273H6.6A1.262,1.262,0,0,0,7.853,1.136,1.262,1.262,0,0,0,6.6,0H1.254A1.262,1.262,0,0,0,0,1.136,1.262,1.262,0,0,0,1.254,2.273Z"
          transform="translate(1619.822 146.946)"
          fill="#3a3a3a"
        />
      </g>
    </svg>
  );
};

export default MenuIcon;
