const ArrowUpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.037"
      height="6.932"
      viewBox="0 0 11.037 6.932"
    >
      <path
        id="Path_166"
        data-name="Path 166"
        d="M-7.156,3022.843l4.1,4.1,4.1-4.1"
        transform="translate(2.467 3028.361) rotate(180)"
        fill="none"
        stroke="#c4c4c4"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ArrowUpIcon;
