import { useEffect, useRef } from "react";
const useClickOutSide = (handler: () => void) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const hamdleMouseDown = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        handler();
      }
    };
    window.addEventListener("mousedown", (e) => hamdleMouseDown(e));
    return () => {
      window.removeEventListener("mousedown", (e) => hamdleMouseDown(e));
    };
  }, [handler]);
  return ref;
};
export default useClickOutSide;
