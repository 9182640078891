import classNames from "classnames";
import { FC, PropsWithChildren, Children, cloneElement, ChangeEvent, useMemo } from "react";
import useTranslation from "../../hooks/useTranslation";

type IOpeningSide = "top" | "bottom" | "left" | "right";

interface Props extends PropsWithChildren {
  isOpen?: boolean;
  close?: () => void;
  open?: () => void;
  onClick_close_item?: boolean;
  className?: string;
  search?: {
    value?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  openingSide?: IOpeningSide;
}

const Select: FC<Props> = ({ isOpen, close, onClick_close_item = true, className, search, openingSide = "bottom", children }) => {
  const t = useTranslation();
  const childNode = Children.map(children, (child: any) => {
    // if (child.type.name === "SelectItem") {
    const clone = cloneElement(child, {
      // close: onClick_close_item && (close),
      close: (close && onClick_close_item) ? close : () => {},
    });
    return clone;
    // } else {
    //   const clone = cloneElement(child, {});
    //   return clone;
    // }
  });

  const position_openingSide = {
    bottom: "left-0 top-full rounded-b-lg",
    top: "left-0 bottom-full rounded-t-lg",
    right: "top-0 left-full",
    left: "top-0 right-full",
  };
  let placeholder = useMemo(() => `${t("search")} ...`, [t]);
  return (
    <div
      style={{
        maxHeight: "250px",
        overflowY: "auto",
      }}
      className={classNames(className, position_openingSide[openingSide], "absolute z-50 w-full", {
        hidden: !isOpen,
        block: isOpen,
      })}
    >
      <ul className={classNames({ flex: openingSide === "left" || openingSide === "right" })}>
        {!!search && (
          <li>
            <input
              autoFocus
              className="w-full p-2 outline-none bg-bg-primary text-text-main text-xs border-b border-zinc-600"
              placeholder={placeholder}
              value={search.value}
              onChange={search.onChange}
            />
          </li>
        )}
        {childNode}
      </ul>
    </div>
  );
};

export default Select;
