import { toast } from "react-toastify";
import TwitterIcon from "../assets/icons/TwitterIcon";
import FacebookIcon from "../assets/icons/FacebookIcon";
import InstagramIcon from "../assets/icons/InstagramIcon";
import { ITask } from "../types";
import Language from "../i18n";

type ISocialMedia = "TWITTER" | "FACEBOOK" | "INSTAGRAM";
type ISearchBy = "USERNAME" | "EMAIL" | "PHONE";
export const singleSocialMediaId = (item: ISocialMedia) => {
  if (item === "TWITTER") {
    return "1";
  } else if (item === "FACEBOOK") {
    return "2";
  } else if (item === "INSTAGRAM") {
    return "3";
  }
};
export const singleSocialMediaName = (id: "1" | "2" | "3") => {
  if (id === "1") {
    return "TWITTER";
  } else if (id === "2") {
    return "FACEBOOK";
  } else if (id === "3") {
    return "INSTAGRAM";
  }
};
export const multiSocialMedia = (items: ISocialMedia[]) => {
  let arr: string[] = [];
  items.forEach((i) => {
    if (i === "TWITTER") {
      arr.push("1");
    } else if (i === "FACEBOOK") {
      arr.push("2");
    } else if (i === "INSTAGRAM") {
      arr.push("3");
    }
  });
  return arr;
};
export const singleSocialIcon = (id: "1" | "2" | "3", size: "sm" | "md" = "md") => {
  if (size === "sm") {
    if (id === "1") {
      return <TwitterIcon width="20" height="18" />;
    } else if (id === "2") {
      return <FacebookIcon width="8" height="16" />;
    } else if (id === "3") {
      return <InstagramIcon width="18" height="18" />;
    } else {
      return;
    }
  } else {
    if (id === "1") {
      return <TwitterIcon width={""} />;
    } else if (id === "2") {
      return <FacebookIcon />;
    } else if (id === "3") {
      return <InstagramIcon />;
    } else {
      return;
    }
  }
};
export const searchTypeId = (item: ISearchBy) => {
  if (item === "USERNAME") {
    return "1";
  } else if (item === "EMAIL") {
    return "2";
  } else if (item === "PHONE") {
    return "3";
  }
};
export const searchTypeText = (item: ISearchBy) => {
  if (item === "USERNAME") {
    return <Language _t="username" />;
  } else if (item === "EMAIL") {
    return <Language _t="email" />;
  } else if (item === "PHONE") {
    return <Language _t="phone_number" />;
  }
};
export const taskParser = (id: string | number) => {
  if (id == "1") {
    return "Follower";
  } else if (id == "2") {
    return "Following";
  } else if (id == "3") {
    return "Post";
  } else {
    return "Non Valid";
  }
};
export const multiTaskParserName = (task: ITask[]) => {
  let arr: string[] = [];
  task.forEach((i) => {
    if (i === "Follower") {
      arr.push("1");
    } else if (i === "Following") {
      arr.push("2");
    } else if (i === "Post") {
      arr.push("3");
    }
  });
  return arr;
};
export const handleCatchError = (error: any) => {
  const msg = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
  if (typeof msg === "string") {
    toast(msg, { type: "error" });
  } else {
    toast("Error_", { type: "error" });
  }
};
export const selectReal = (targets: boolean, cases: boolean) => {
  if (targets && cases) {
    return "2";
  } else if (!targets && cases) {
    return "0";
  } else if (targets && !cases) {
    return "1";
  } else {
    return "0";
  }
};
export const crawlStatus = (status: 0 | 1 | 2 | 3 | 4 | "Unknown") => {
  const checkStatus = {
    0: <Language _t="Waiting_for_the_creep" />,
    1: <Language _t="doing" />,
    2: <Language _t="Successful_crawl" />,
    3: <Language _t="Crawl_failed" />,
    4: <Language _t="success_empty" />,
    Unknown: <Language _t="Unknown" />,
  };
  return checkStatus[status];
  // const checkStatus = (s: number) => (status === s ? true : false);
  // if (checkStatus(0)) {
  //   return <Language _t="Waiting_for_the_creep" />;
  // } else if (checkStatus(1)) {
  //   return <Language _t="doing" />;
  // } else if (checkStatus(2)) {
  //   return <Language _t="Successful_crawl" />;
  // } else if (checkStatus(3)) {
  //   return <Language _t="Crawl_failed" />;
  // } else if (checkStatus(3)) {
  //   return <Language _t="success_empty" />;
  // } else {
  //   return <Language _t="Unknown" />;
  // }
};
export const intersectionStatus = (status: number) => {
  const checkStatus = (s: number) => (status === s ? true : false);
  if (checkStatus(0)) {
    return <Language _t="Waiting_to_start" />;
  } else if (checkStatus(1)) {
    return <Language _t="doing" />;
  } else if (checkStatus(2)) {
    return <Language _t="successful" />;
  } else if (checkStatus(3)) {
    return <Language _t="Unsuccessful" />;
  } else {
    return <Language _t="Unknown" />;
  }
};
export const typeCondition = (follower: number | string | null, following: number | string | null, friend: number | string | null) => {
  if (friend === 1) {
    return "Friend";
  } else {
    if (following === 1 && follower === 1) {
      return <Language _t="two_way" />;
    } else if (follower === 1 && !following) {
      return <Language _t="follower" />;
    } else if (!follower && following === 1) {
      return <Language _t="following" />;
    } else {
      return "";
    }
  }
};
