import React from "react";

const Users = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.773" height="16.791" viewBox="0 0 21.773 16.791">
      <g id="Iconly_Light_3_User" data-name="Iconly/Light/3 User" transform="translate(0.75 0.75)">
        <g id="_3_User" data-name="3 User" transform="translate(-0.75 -0.75)">
          <path
            id="Stroke_1"
            data-name="Stroke 1"
            d="M0,5.677A2.876,2.876,0,0,0,2.472,2.836,2.875,2.875,0,0,0,.066,0"
            transform="translate(16.888 1.72)"
            fill="none"
            stroke="#c4c4c4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Stroke_3"
            data-name="Stroke 3"
            d="M0,0C1.351.2,2.294.675,2.294,1.65c0,.671-.444,1.107-1.162,1.381"
            transform="translate(18.729 10.75)"
            fill="none"
            stroke="#c4c4c4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Stroke_5"
            data-name="Stroke 5"
            d="M5.959,0C2.745,0,0,.487,0,2.432S2.728,4.877,5.959,4.877c3.214,0,5.958-.482,5.958-2.428S9.19,0,5.959,0Z"
            transform="translate(4.928 11.164)"
            fill="none"
            stroke="#c4c4c4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Stroke_7"
            data-name="Stroke 7"
            d="M3.819,7.638a3.805,3.805,0,1,0-.028,0Z"
            transform="translate(7.068 0.75)"
            fill="none"
            stroke="#c4c4c4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Stroke_9"
            data-name="Stroke 9"
            d="M2.472,5.677A2.871,2.871,0,0,1,2.406,0"
            transform="translate(2.413 1.72)"
            fill="none"
            stroke="#c4c4c4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Stroke_11"
            data-name="Stroke 11"
            d="M2.294,0C.943.2,0,.675,0,1.65c0,.671.444,1.107,1.162,1.381"
            transform="translate(0.75 10.75)"
            fill="none"
            stroke="#c4c4c4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default Users;
