import classNames from "classnames";
import { PropsWithChildren, FC } from "react";
import ArrowUpIcon from "../../assets/icons/ArrowUpIcon";
import ArrowDownIcon from "../../assets/icons/ArrowDownIcon";
import { twMerge } from "tailwind-merge";

interface Props extends PropsWithChildren {
  className?: string;
  isOpen?: boolean;
  close?: () => void;
  open?: () => void;
  hasArrow?: boolean;
}

const LabelSelect: FC<Props> = ({
  className,
  close,
  isOpen,
  open,
  hasArrow = true,
  children,
}) => {
  const handleToggleClick = () => {
    if (close && open) {
      if (isOpen) {
        close();
      } else {
        open();
      }
    }
  };
  return (
    <div
      onClick={handleToggleClick}
      className={twMerge(
        classNames(className, "cursor-pointer flex items-center", {
          rounded: !isOpen,
          "rounded-none": isOpen,
          "px-4": hasArrow,
        })
      )}
    >
      <div
        className={classNames("truncate flex-grow text-text-primary")}
      >
        {children}
      </div>
      {hasArrow && <div>{isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}</div>}
    </div>
  );
};

export default LabelSelect;
