import { Method } from "axios";
interface IApi {
  path: string;
  method: Method;
}
type bank = "banks" | "create" | "search" | "target_count" | "social_info" | "targets" | "move_to_bank";
type home = "info" | "events";
type auth = "login" | "logout";
type account = "all" | "create" | "edit" | "delete" | "twitter" | "facebook" | "instagram";
type crawl = "all" | "create" | "search" | "delete" | "suggest" | "group" | "start_crawl" | "stop_crawl";
type search = "search_target" | "search_case" | "get_case" | "graph" | "case_relation" | "graph_info" | "accept";
type target = "delete_target" | "update_grade" | "add_star" | "remove_star" | "add_tag" | "delete_tag" | "search_friends" | "add_tags";
type interSection = "add" | "list" | "result" | "history_delete";
type user = "list" | "user" | "create" | "edit" | "change_password" | "delete";
type tag = "create" | "list" | "remove";
type projects_r = "get_all" | "create" | "dashboard";
type sites_r = "get_sites" | "get_news";
type logger = "activity" | "filter";

type LoggerAPI = {
  [key in logger]: IApi;
};
type TargetAPI = {
  [key in target]: IApi;
};
type BankAPI = {
  [key in bank]: IApi;
};
type HomeAPI = {
  [key in home]: IApi;
};
type AuthAPI = {
  [key in auth]: IApi;
};
type AccountAPI = {
  [key in account]: IApi;
};
type CrawlAPI = {
  [key in crawl]: IApi;
};
type SearchAPI = {
  [key in search]: IApi;
};
type InterSectionAPI = {
  [key in interSection]: IApi;
};
type IUserManagementAPI = {
  [key in user]: IApi;
};
type ITagAPI = {
  [key in tag]: IApi;
};
type IProject_R_API = {
  [key in projects_r]: IApi;
};
type ISites_R_API = {
  [key in sites_r]: IApi;
};
type API = {
  auth: AuthAPI;
  home: HomeAPI;
  bank: BankAPI;
  account: AccountAPI;
  crawl: CrawlAPI;
  search: SearchAPI;
  target: TargetAPI;
  interSection: InterSectionAPI;
  userManagement: IUserManagementAPI;
  tag: ITagAPI;
  project_r: IProject_R_API;
  sites: ISites_R_API;
  logger: LoggerAPI;
};

export const api: API = {
  auth: {
    login: {
      path: "/auth/login",
      method: "POST",
    },
    logout: {
      path: "/auth/logout",
      method: "GET",
    },
  },
  home: {
    info: {
      path: "/home/index",
      method: "GET",
    },
    events: {
      method: "GET",
      path: "/home/events",
    },
  },
  bank: {
    banks: {
      method: "GET",
      path: "/bank/all",
    },
    create: {
      method: "POST",
      path: "/bank/create",
    },
    search: {
      method: "POST",
      path: "/bank/search",
    },
    target_count: {
      method: "GET",
      path: "/bank/target_count/", // id
    },
    social_info: {
      method: "GET",
      path: "/bank/social_info/", // id
    },
    targets: {
      method: "POST",
      path: "/bank/targets",
    },
    move_to_bank: {
      method: "POST",
      path: "/target/add_to_bank",
    },
  },
  account: {
    all: {
      method: "GET",
      path: "/account/all/0",
    },
    twitter: {
      method: "GET",
      path: "/account/all/1",
    },
    facebook: {
      method: "GET",
      path: "/account/all/2",
    },
    instagram: {
      method: "GET",
      path: "/account/all/3",
    },
    create: {
      method: "POST",
      path: "/account/create",
    },
    delete: {
      method: "GET",
      path: "/account/delete/", // end => /id
    },
    edit: {
      method: "POST",
      path: "/account/edit",
    },
  },
  crawl: {
    all: {
      method: "GET",
      path: "/crawl/all",
    },
    create: {
      method: "POST",
      path: "/crawl/create",
    },
    delete: {
      method: "GET",
      path: "/crawl/delete/", // id
    },
    search: {
      method: "POST",
      path: "/crawl/search",
    },
    suggest: {
      method: "POST",
      path: "/search/suggest",
    },
    group: {
      path: "/create/group",
      method: "POST",
    },
    start_crawl: {
      path: "/crawl/start_crawl/", // id
      method: "GET",
    },
    stop_crawl: {
      path: "/crawl/stop_crawl/", // id
      method: "GET",
    },
  },
  search: {
    search_target: {
      path: "/search/target",
      method: "POST",
    },
    search_case: {
      path: "/search/case/", // id
      method: "GET",
    },
    get_case: {
      path: "/search/case/", // id,
      method: "GET",
    },
    case_relation: {
      path: "/search/case",
      method: "POST",
    },
    graph: {
      method: "GET",
      path: "/search/graph/", // id
    },
    graph_info: {
      method: "POST",
      path: "/search/graph/info", // id
    },
    accept: {
      method: "POST",
      path: "/search/case/accept",
    },
  },
  target: {
    delete_target: {
      path: "/search/delete/", // id
      method: "GET",
    },
    update_grade: {
      path: "/target/update_grade",
      method: "POST",
    },
    add_star: {
      method: "GET",
      path: "/star/add/", // id
    },
    remove_star: {
      method: "GET",
      path: "/star/remove/", // id
    },
    add_tag: {
      path: "/tag/add_target",
      method: "POST",
    },
    add_tags: {
      path: "/tag/add_targets",
      method: "POST",
    },
    delete_tag: {
      method: "POST",
      path: "/tag/remove_target",
    },
    search_friends: {
      method: "POST",
      path: "/search/friend",
    },
  },
  interSection: {
    add: {
      method: "POST",
      path: "/intersection/member/add",
    },
    list: {
      path: "/intersection/get",
      method: "GET",
    },
    result: {
      path: "/intersection/result/", // id
      method: "GET",
    },
    history_delete: {
      method: "GET",
      path: "/intersection/history/delete/", // id
    },
  },
  userManagement: {
    list: {
      method: "GET",
      path: "/user/get",
    },
    user: {
      method: "GET",
      path: "/user/get/", // id
    },
    create: {
      method: "POST",
      path: "/user/create",
    },
    edit: {
      method: "POST",
      path: "/user/edit",
    },
    change_password: {
      method: "POST",
      path: "/user/change",
    },
    delete: {
      method: "GET",
      path: "/user/delete/", // id
    },
  },
  tag: {
    create: {
      method: "POST",
      path: "/tag/create",
    },
    list: {
      method: "GET",
      path: "/tag/all",
    },
    remove: {
      method: "GET",
      path: "/tag/remove/", // id
    },
  },
  project_r: {
    create: {
      method: "POST",
      path: "/rased/project/create",
    },
    get_all: {
      method: "GET",
      path: "/rased/project/all",
    },
    dashboard: {
      method: "GET",
      path: "/rased/dashboard",
    },
  },
  sites: {
    get_sites: {
      method: "GET",
      path: "/rased/sites/get/", // id
    },
    get_news: {
      method: "POST",
      path: "/rased/sites/news/get",
    },
  },
  logger: {
    activity: {
      method: "GET",
      path: "/activity", // page
    },
    filter: {
      method: "POST",
      path: "/history_filter",
    },
  },
};
