const TwitterIcon = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24.796"}
      height={height || "20.626"}
      viewBox="0 0 24.796 20.626"
    >
      <path
        id="twitter"
        d="M24.4,36.89a9.818,9.818,0,0,1-1.71.577A5.331,5.331,0,0,0,24.1,35.226h0a.28.28,0,0,0-.409-.326h0a9.865,9.865,0,0,1-2.614,1.08.666.666,0,0,1-.163.02.693.693,0,0,1-.458-.175,5.346,5.346,0,0,0-3.537-1.335,5.716,5.716,0,0,0-1.694.261,5.179,5.179,0,0,0-3.49,3.732,5.7,5.7,0,0,0-.127,1.968.191.191,0,0,1-.048.15.2.2,0,0,1-.147.067h-.019a14.017,14.017,0,0,1-9.634-5.147h0a.28.28,0,0,0-.458.036h0a5.352,5.352,0,0,0,.87,6.5,4.791,4.791,0,0,1-1.219-.472h0a.279.279,0,0,0-.415.241h0a5.353,5.353,0,0,0,3.122,4.931H3.544a4.81,4.81,0,0,1-.9-.086h0a.28.28,0,0,0-.319.36h0a5.358,5.358,0,0,0,4.232,3.644,9.864,9.864,0,0,1-5.525,1.678H.413a.409.409,0,0,0-.4.309.423.423,0,0,0,.2.468,14.672,14.672,0,0,0,7.373,1.988,14.873,14.873,0,0,0,6.3-1.337,13.913,13.913,0,0,0,4.61-3.454A15.145,15.145,0,0,0,21.322,45.6a14.935,14.935,0,0,0,.959-5.174v-.081a.91.91,0,0,1,.34-.708A10.566,10.566,0,0,0,24.748,37.3h0a.28.28,0,0,0-.346-.411Z"
        transform="translate(0 -34.491)"
        fill="#fff"
      />
    </svg>
  );
};

export default TwitterIcon;
