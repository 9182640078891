const Projects = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="20.5" viewBox="0 0 20.5 20.5">
      <g id="Iconly_Light_Category" data-name="Iconly/Light/Category" transform="translate(0.75 0.75)">
        <g id="Category">
          <path
            id="Stroke_1"
            data-name="Stroke 1"
            d="M0,3.694C0,.923.03,0,3.694,0S7.389.923,7.389,3.694,7.4,7.389,3.694,7.389,0,6.466,0,3.694Z"
            transform="translate(0)"
            fill="none"
            stroke="#c4c4c4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Stroke_3"
            data-name="Stroke 3"
            d="M0,3.694C0,.923.03,0,3.694,0S7.389.923,7.389,3.694,7.4,7.389,3.694,7.389,0,6.466,0,3.694Z"
            transform="translate(11.611)"
            fill="none"
            stroke="#c4c4c4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Stroke_5"
            data-name="Stroke 5"
            d="M0,3.694C0,.923.03,0,3.694,0S7.389.923,7.389,3.694,7.4,7.389,3.694,7.389,0,6.466,0,3.694Z"
            transform="translate(0 11.611)"
            fill="none"
            stroke="#c4c4c4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Stroke_7"
            data-name="Stroke 7"
            d="M0,3.694C0,.923.03,0,3.694,0S7.389.923,7.389,3.694,7.4,7.389,3.694,7.389,0,6.466,0,3.694Z"
            transform="translate(11.611 11.611)"
            fill="none"
            stroke="#c4c4c4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default Projects;
