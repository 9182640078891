import { Suspense, useEffect } from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import { Chart, registerables } from "chart.js";
import { ToastContainer } from "react-toastify";
import ThemeProvider from "./theme/ThemeConfig";
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import ProtectedRoute from "./utils/ProtectedRoute";
import PublicRoute from "./utils/PublicRoute";
import Loading from "./common/loading/Loading";
import AxiosProvider from "./services/http";
import packageJson from "./../package.json";
import { getLocalStorage, setLocalStorage } from "./utils/storages";
import { useDispatch } from "react-redux";
import { changeLanguage } from "./redux/features/config/configSlice";
import "react-toastify/dist/ReactToastify.css";
import "./assets/app.scss";

Chart.register(...registerables);

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const caching = () => {
      let version = localStorage.getItem("version");
      if (version !== packageJson.version) {
        if ("caches" in window) {
          caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach((name) => {
              caches.delete(name);
            });
          });

          // Makes sure the page reloads. Changes are only visible after you refresh.
          window.location.reload();
        }

        localStorage.clear();
        localStorage.setItem("version", packageJson.version);
      }
    };
    caching();
    if (getLocalStorage("language")) {
      dispatch(changeLanguage(getLocalStorage("language")));
    } else {
      setLocalStorage("language", "fa");
      document.body.dir = "rtl";
    }
    // if (getLocalStorage("help") && getLocalStorage("help") === true) {
    //   document.querySelector("#help")?.remove();
    // } else {
    //   setLocalStorage("help", false);
    // }
  }, [dispatch]);

  return (
    <Suspense fallback={<Loading className="w-full flex justify-center items-center" height={"100vh"} />}>
      <ThemeProvider>
        {/* <Help /> */}
        <ToastContainer className={"text-right"} theme="dark" progressStyle={{ backgroundColor: "rgb(255,0,0)" }} />
        <AxiosProvider>
          <Routes>
            <Route
              index
              path="/iwf/*"
              element={
                <ProtectedRoute>
                  <MainLayout />
                </ProtectedRoute>
              }
            />
            <Route
              path="/auth/*"
              element={
                <PublicRoute>
                  <AuthLayout />
                </PublicRoute>
              }
            />
            <Route path="*" element={<Navigate to={"/iwf"} />} />
          </Routes>
        </AxiosProvider>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
