import React from "react";

const Home = () => {
  return (
    <svg id="Iconly_Bulk_Home" data-name="Iconly/Bulk/Home" xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
      <g id="Home">
        <path
          id="Home-2"
          data-name="Home"
          d="M6.993,19.721V16.5a1.491,1.491,0,0,1,1.489-1.484h3.025a1.491,1.491,0,0,1,1.5,1.484h0v3.211A1.294,1.294,0,0,0,14.3,21h2.064a3.647,3.647,0,0,0,2.572-1.049A3.576,3.576,0,0,0,20,17.406V8.259a2.594,2.594,0,0,0-.943-2L12.045.708A3.286,3.286,0,0,0,7.879.783L1.018,6.262A2.6,2.6,0,0,0,0,8.259V17.4A3.621,3.621,0,0,0,3.638,21H5.655a1.3,1.3,0,0,0,.919-.371,1.273,1.273,0,0,0,.382-.908Z"
          fill="#c4c4c4"
        />
      </g>
    </svg>
  );
};

export default Home;
