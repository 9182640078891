import { Navigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { getLocalStorage } from "./storages";
const PublicRoute = ({ children }: any) => {
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  const token_local = getLocalStorage('token')


  if (token_local) {
    return <Navigate to={"/iwf"} />;
  }
  return children;
};

export default PublicRoute;
