import { lazy, Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import Container from "../components/container/Container";
import Header from "./../common/header";
import Sidebar from "../common/sidebar";
import Wrapper from "../common/wrapper";
import useToggle from "../hooks/useToggle";

const Main = lazy(() => import("./../pages/main"));
const BanksList = lazy(() => import("./../pages/banks"));
const SingleBank = lazy(() => import("./../pages/singleBank"));
const CrawlList = lazy(() => import("../pages/crawlList"));
const Search = lazy(() => import("./../pages/search"));
const SearchResult = lazy(() => import("./../pages/searchResult"));
const Intersection = lazy(() => import("./../pages/intersection"));
const AccountManagement = lazy(() => import("./../pages/accounts"));
const UserManagment = lazy(() => import("./../pages/userManagment"));
const CaseInfo = lazy(() => import("./../pages/case-info"));
const TargetInfo = lazy(() => import("./../pages/target-info"));
const ConnectionGraph = lazy(() => import("./../pages/connectionGraph"));
const HistoryIntersection = lazy(() => import("./../pages/history-intersection"));
const ResultIntersection = lazy(() => import("./../pages/result-intersection"));
const Logger = lazy(() => import("./../pages/logger"));

const MainLayout = () => {
  const { isFalse: closeSidebar, isTrue: openSidebar, state: isOpenSidebar } = useToggle();

  return (
    <Fragment>
      <Wrapper>
        <Header openSidebar={openSidebar} />
        <Sidebar isOpenSidebar={isOpenSidebar} closeSidebar={closeSidebar} />
        <Container>
          <Routes>
            <Route index path="/" Component={Main} />
            <Route path="/banks-list" element={<BanksList />} />
            <Route path="/banks-list/:id" element={<SingleBank />} />
            <Route path="/crawl-list" element={<CrawlList />} />
            <Route path="/search" element={<Search />} />
            <Route path="/search-result" element={<SearchResult />} />
            <Route path="/intersection" element={<Intersection />} />
            <Route path="/account-management" element={<AccountManagement />} />
            <Route path="/user-management" element={<UserManagment />} />
            <Route path="/case-info" element={<CaseInfo />} />
            <Route path="/target-info" element={<TargetInfo />} />
            <Route path="/connection-graph" element={<ConnectionGraph />} />
            <Route path="/history-intersection" element={<HistoryIntersection />} />
            <Route path="/result-intersection" element={<ResultIntersection />} />
            <Route path="/logger" element={<Logger />} />
          </Routes>
        </Container>
      </Wrapper>
    </Fragment>
  );
};

export default MainLayout;
