import { InputHTMLAttributes, useId, forwardRef, Fragment } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

interface IIcon {
  position: "start" | "end";
  icon: JSX.Element | string;
}
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element;
  inputClassName?: string;
  boxInputClassName?: string;
  failed?: boolean;
  textError?: string;
  icons?: IIcon[];
  labelClassName?: string;
}

const StandardField = forwardRef<any, Props>(({ labelClassName, label, inputClassName, failed, textError, icons, boxInputClassName, ...input }, ref) => {
  const { language: lang } = useSelector((state: RootState) => state.config);
  const id = useId();
  return (
    <Fragment>
      <div
        className={twMerge(
          classNames("peer relative bg-bg-secondary flex items-center rounded-lg w-full", boxInputClassName, {
            "flex-row-reverse": icons?.length && (icons[0]?.position === "start" || icons[1]?.position === "start"),
            "flex-row": icons?.length && (icons[0]?.position === "end" || icons[1]?.position === "end"),
          })
        )}
      >
        <div
          className={classNames({
            "w-[calc(100%_-_50px)]": icons && icons.length,
            "w-full": !icons?.length,
          })}
        >
          <input
            ref={ref && ref}
            type="text"
            className={twMerge(
              classNames("w-full bg-bg-secondary px-2 py-2 outline-none rounded-lg placeholder-shown:text-text-main text-text-main peer", inputClassName, {
                "border-2 border-zinc-800": !icons?.length,
                "border-2 border-red-500": failed,
              })
            )}
            id={input.id || id}
            placeholder=" "
            {...input}
          />
          {label && (
            <label
              className={classNames(
                labelClassName,
                "absolute text-sm text-text-primary transition-all transform -translate-y-5 scale-75 top-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5",
                {
                  "left-2.5": lang === "en",
                  "right-2.5": lang !== "en",
                }
              )}
              htmlFor={input.id || id}
            >
              {label}
            </label>
          )}
        </div>
        {icons?.length &&
          icons.map((icon, index) => (
            <div key={index} className={classNames("flex justify-center w-[calc(50px)] px-2")}>
              {icon.icon}
            </div>
          ))}
        {textError && failed && <div className={classNames("text-error text-xs mb-2 absolute -bottom-6", { "left-0": lang === "en", "right-0": lang !== "en" })}>{textError}</div>}
      </div>
    </Fragment>
  );
});

export default StandardField;
