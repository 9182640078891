import CommonIcon from "../assets/icons/CommonIcon";
import ConnectionGraphIcon from "../assets/icons/ConnectionGraphIcon";
import DBIcon from "../assets/icons/DBIcon";
import DesktopIcon from "../assets/icons/DesktopIcon";
import ListIcon from "../assets/icons/ListIcon";
import SearchIcon from "../assets/icons/SearchIcon";
import UserIcon from "../assets/icons/UserIcon";
import Language from "../i18n";
import HomeIcon from "./../assets/icons/Home";
import ProjectsIcon from "./../assets/icons/Projects";
import UsersIcon from "./../assets/icons/Users";
import BaseUrl from "./../services/config.json";

export interface ISubData {
  id: number;
  title: string | JSX.Element;
  icon: any;
  path: string;
}

export interface ISidebarData {
  id: number;
  title: string | JSX.Element;
  icon: any;
  path: string | null;
  children?: ISubData[];
}

export const sidebarData: ISidebarData[] = [
  {
    id: 1,
    title: <Language _t="desk" />,
    path: "/iwf/",
    icon: <DesktopIcon />,
  },
  {
    id: 2,
    title: <Language _t="List_of_banks" />,
    path: "/iwf/banks-list",
    icon: <DBIcon />,
  },
  {
    id: 3,
    title: <Language _t="Creep_list" />,
    path: "/iwf/crawl-list",
    icon: <ListIcon />,
  },
  {
    id: 4,
    title: <Language _t="search" />,
    path: "/iwf/search",
    icon: <SearchIcon />,
  },
  {
    id: 5,
    title: <Language _t="intersection" />,
    // path: "/iwf/intersection",
    path: null,
    children: [
      {
        id: 1,
        icon: <CommonIcon />,
        path: "/iwf/intersection",
        title: <Language _t="tabular" />,
      },
      {
        id: 2,
        icon: <CommonIcon />,
        path: `${BaseUrl.baseAddress}/panel/index.html`,
        title: <Language _t="Graph" />,
      },
    ],
    icon: <CommonIcon />,
  },
  {
    id: 6,
    title: <Language _t="The_history_of_intersectionality" />,
    path: "/iwf/history-intersection",
    icon: <CommonIcon />,
  },
  {
    id: 7,
    title: <Language _t="Account_management" />,
    path: "/iwf/account-management",
    icon: <ConnectionGraphIcon />,
  },
  {
    id: 8,
    title: <Language _t="User_management" />,
    path: "/iwf/user-management",
    icon: <UserIcon />,
  },
  {
    id: 9,
    title: <Language _t="logger" />,
    path: "/iwf/logger",
    icon: <ListIcon />,
  },
];
export const rased_sidebarData = [
  {
    id: 1,
    title: <Language _t="desk" />,
    path: "/rased/main",
    icon: <HomeIcon />,
  },
  {
    id: 2,
    title: "پروژه ها",
    path: "/rased/projects",
    icon: <ProjectsIcon />,
  },
  {
    id: 3,
    title: "کاربران",
    path: "/rased/users",
    icon: <UsersIcon />,
  },
];
