import fa from "./fa.json";
import ar from "./ar.json";
import en from "./en.json";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import store from './../redux/index'
const languages: any = {
  fa,
  ar,
  en
};
const Language = ({ _t }: { _t: string }) => {
  const { language } = useSelector((state: RootState) => state.config);
  return languages[language][_t] ? languages[language][_t] : "---";
};

export const translate = (key:string):string => {
  const lang = store.getState().config.language
  return languages[lang][key] ? languages[lang][key] : "---";
}

export default Language;
