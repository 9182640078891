import { FC, PropsWithChildren, useEffect } from "react";
import Portal from "../../utils/Portal";
import useClickOutSide from "../../hooks/useClickOutSide";
import classNames from "classnames";
import { freezeScreen } from "../../utils/freezScreen";

interface IModal extends PropsWithChildren {
  isOpen: boolean;
  close: () => void;
  size?: "sm" | "md" | "lg" | "xl";
  className?: string;
}

const Modal: FC<IModal> = ({
  close,
  isOpen,
  size = "md",
  className,
  children,
}) => {
  const sizeModal = {
    sm: "w-full lg:w-2/12",
    md: "w-full lg:w-4/12",
    lg: "w-full lg:w-6/12",
    xl: "w-full lg:w-8/12",
    "2xl": "w-full lg:w-10/12",
  };
  useEffect(() => {
    if (isOpen) {
      freezeScreen(true);
    } else {
      freezeScreen(false);
    }
  }, [isOpen]);
  const ref = useClickOutSide(() => close());
  return isOpen ? (
    <Portal>
      <div className="w-full h-screen fixed left-0 top-0 backdrop-blur-md z-40" />
      <div
        ref={ref}
        className={classNames(
          className,
          "z-50 fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
          sizeModal[size]
        )}
      >
        {children}
      </div>
    </Portal>
  ) : null;
};

export default Modal;
