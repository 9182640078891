const LogoutIcon = () => {
  return (
    <svg
      id="Logout"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
    >
      <path
        id="Logout-2"
        data-name="Logout"
        d="M4.33,19A4.284,4.284,0,0,1,0,14.775a.74.74,0,0,1,.75-.731.74.74,0,0,1,.75.731A2.8,2.8,0,0,0,4.33,17.537H9.077A2.79,2.79,0,0,0,11.9,14.785v-.894a.75.75,0,0,1,1.5,0v.894A4.273,4.273,0,0,1,9.077,19Zm11.543-6.219a.719.719,0,0,1,0-1.035l1.56-1.514H7.549a.732.732,0,1,1,0-1.464h11.7a.75.75,0,0,1,.693.453.721.721,0,0,1-.164.8l-2.846,2.764a.763.763,0,0,1-1.06,0ZM0,11.449V4.215A4.274,4.274,0,0,1,4.322,0H9.068A4.284,4.284,0,0,1,13.4,4.225v.885a.75.75,0,0,1-1.5,0V4.225A2.8,2.8,0,0,0,9.068,1.463H4.322A2.79,2.79,0,0,0,1.5,4.215v7.234a.742.742,0,0,1-.751.732A.741.741,0,0,1,0,11.449Zm16.594-3.5-.718-.692a.718.718,0,0,1,0-1.034.76.76,0,0,1,1.06-.005l.717.691a.719.719,0,0,1,.005,1.035.763.763,0,0,1-1.06.005Z"
        fill="#b94d4d"
      />
    </svg>
  );
};

export default LogoutIcon;
