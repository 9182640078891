import { Children, FC, PropsWithChildren, cloneElement } from "react";
import useToggle from "../../hooks/useToggle";
import useClickOutSide from "../../hooks/useClickOutSide";
import classNames from "classnames";
import CSS from "csstype";

interface Props extends PropsWithChildren {
  className?: string;
  style?: CSS.Properties;
}

const FormSelect: FC<Props> = ({ className, style, children }) => {
  const { isFalse, isTrue, state } = useToggle();
  const childsNode = Children.map(children, (child: any) => {
    const clone = cloneElement(child, {
      isOpen: state,
      close: isFalse,
      open: isTrue,
    });
    return clone;
  });
  const ref = useClickOutSide(() => {
    state && isFalse();
  });
  return (
    <div
      ref={ref}
      style={style}
      className={classNames(className, "relative inline-block")}
    >
      {childsNode}
    </div>
  );
};

export default FormSelect;
