const ListIcon = () => {
  return (
    <svg
      id="Iconly_Light-outline_Document"
      data-name="Iconly/Light-outline/Document"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Document" transform="translate(3 2)">
        <path
          id="Document-2"
          data-name="Document"
          d="M5.138,20a6.942,6.942,0,0,1-1.171-.094l-.218-.041A4.447,4.447,0,0,1,.986,18.179,5.547,5.547,0,0,1,0,14.791V5.209a7.431,7.431,0,0,1,.06-.96C.41,1.549,2.262,0,5.138,0h7.725C16.05,0,17.97,1.919,18,5.132v9.659C18,18.052,16.079,20,12.863,20ZM1.437,5.209v9.581c0,2.489,1.245,3.752,3.7,3.752h7.716c2.45,0,3.692-1.263,3.692-3.752V5.209c0-2.489-1.242-3.752-3.692-3.752H5.138C2.682,1.457,1.437,2.72,1.437,5.209Zm3.945,9.749a.724.724,0,0,1,0-1.448h7.211a.724.724,0,0,1,0,1.448Zm7.211-4.229H5.382a.735.735,0,0,1,0-1.458h7.211a.722.722,0,0,1,.691.339.745.745,0,0,1,0,.779.721.721,0,0,1-.691.339ZM5.391,6.5a.734.734,0,0,1,0-1.457H8.134a.734.734,0,0,1,0,1.457Z"
          transform="translate(0)"
          fill="#c4c4c4"
        />
      </g>
    </svg>
  );
};

export default ListIcon;
