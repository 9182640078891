import { useSelector } from "react-redux";
import { RootState } from "../redux";
import fa from "./../i18n/fa.json";
import ar from "./../i18n/ar.json";
import en from "./../i18n/en.json";
const useTranslation = () => {
  const { language } = useSelector((state: RootState) => state.config);
  const languages: any = {
    fa,
    ar,
    en
  };
  const translate = (key: string) => {
    return languages[language][key];
  };
  return translate;
};
export default useTranslation;
