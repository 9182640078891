const InstagramIcon = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"26.158"}
      height={height||"26.164"}
      viewBox="0 0 26.158 26.164"
    >
      <g id="instagram" transform="translate(0 0)">
        <path
          id="Path_178"
          data-name="Path 178"
          d="M26.541,7.692a9.56,9.56,0,0,0-.608-3.174A6.7,6.7,0,0,0,22.1.69,9.585,9.585,0,0,0,18.93.082C17.53.015,17.085,0,13.533,0s-4,.015-5.392.077A9.563,9.563,0,0,0,4.967.685,6.384,6.384,0,0,0,2.646,2.2,6.442,6.442,0,0,0,1.139,4.513,9.586,9.586,0,0,0,.53,7.687c-.066,1.4-.082,1.845-.082,5.4s.015,4,.077,5.392a9.56,9.56,0,0,0,.608,3.174,6.7,6.7,0,0,0,3.828,3.828,9.586,9.586,0,0,0,3.174.608c1.4.061,1.84.077,5.392.077s4-.015,5.392-.077a9.558,9.558,0,0,0,3.174-.608,6.692,6.692,0,0,0,3.828-3.828,9.592,9.592,0,0,0,.608-3.174c.061-1.4.077-1.84.077-5.392s-.005-4-.066-5.392ZM24.184,18.374a7.171,7.171,0,0,1-.45,2.428,4.34,4.34,0,0,1-2.484,2.484,7.2,7.2,0,0,1-2.428.45c-1.38.061-1.794.077-5.285.077s-3.91-.015-5.285-.077a7.167,7.167,0,0,1-2.428-.45,4.026,4.026,0,0,1-1.5-.976,4.068,4.068,0,0,1-.976-1.5A7.2,7.2,0,0,1,2.9,18.38C2.835,17,2.82,16.585,2.82,13.095s.015-3.91.077-5.285a7.168,7.168,0,0,1,.45-2.428,3.977,3.977,0,0,1,.981-1.5,4.061,4.061,0,0,1,1.5-.976,7.2,7.2,0,0,1,2.428-.45c1.38-.061,1.794-.077,5.285-.077s3.91.015,5.285.077a7.171,7.171,0,0,1,2.428.45,4.023,4.023,0,0,1,1.5.976,4.067,4.067,0,0,1,.976,1.5,7.2,7.2,0,0,1,.45,2.428c.061,1.38.077,1.794.077,5.285s-.015,3.9-.077,5.28Zm0,0"
          transform="translate(-0.449 0)"
          fill="#fff"
        />
        <path
          id="Path_179"
          data-name="Path 179"
          d="M131.67,124.5a6.721,6.721,0,1,0,6.721,6.721A6.723,6.723,0,0,0,131.67,124.5Zm0,11.081a4.36,4.36,0,1,1,4.36-4.36A4.36,4.36,0,0,1,131.67,135.581Zm0,0"
          transform="translate(-118.586 -118.137)"
          fill="#fff"
        />
        <path
          id="Path_180"
          data-name="Path 180"
          d="M365.588,90.171a1.569,1.569,0,1,1-1.569-1.569A1.569,1.569,0,0,1,365.588,90.171Zm0,0"
          transform="translate(-343.948 -84.073)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default InstagramIcon;
