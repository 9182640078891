import { Fragment, FC, PropsWithChildren } from "react";

interface IBaseTheme {
  main: string;
  primary?: string;
  secondary?: string;
}
interface ITheme {
  name: string;
  text: IBaseTheme;
  primary?: {};
  secondary?: {};
  background: IBaseTheme;
  error: IBaseTheme;
}

const darkDefault: ITheme = {
  name: "darkDefault",
  text: {
    main: "rgb(255,255,255)",
    primary: "rgb(196,196,196)",
    secondary: "rgb(122,122,122)",
  },
  primary: {},
  secondary: {},
  background: {
    main: "rgb(0,0,0)",
    primary: "rgb(28,28,28)",
    secondary: "rgb(38,38,38)",
  },
  error: {
    main: "rgb(239,68,68)",
  },
};

const mapTheme = (theme: ITheme): { [key in string]: string } => {
  return {
    "--theme-text-main": theme.text.main,
    "--theme-text-primary": theme.text.primary || "",
    "--theme-text-secondary": theme.text.secondary || "",

    "--theme-bg-main": theme.background.main,
    "--theme-bg-primary": theme.background.primary || "",
    "--theme-bg-secondary": theme.background.secondary || "",

    "--theme-error-main": theme.error.main,
  };
};

const themes = [darkDefault];

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  let themeSelected = "darkDefault";
  themes.forEach((theme) => {
    if (theme.name === themeSelected) {
      Object.keys(mapTheme(theme)).forEach((i) => {
        document.documentElement.style.setProperty(i, mapTheme(theme)[i]);
      });
    }
  });
  return <Fragment>{children}</Fragment>;
};

export default ThemeProvider;
